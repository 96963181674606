import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  papertable: {
    padding: theme.spacing(2),
    elevation: '3',
    display: 'flex',
    overflow: 'visible',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
  },

  papertableMod: {
    padding: theme.spacing(2),
    elevation: '0',
    display: 'flex',
    overflow: 'visible',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]}`, // Always visible border
  },

  divTable: {
    display: 'flex',
    padding: theme.spacing(1),
  },

  buttonRight: {
    'text-align': 'right',
  },

  spinner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

  seeMore: {
    marginTop: theme.spacing(3),
  },

  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[400],
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[500],
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200],
    },
  },

  customScrollbarForMuiTable: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[400],
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[500],
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[200],
    },
  },
}));
