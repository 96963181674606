import React from 'react';
import { useTranslation } from 'react-i18next';

import ColorChip from '../../assets/ColorChip';
import { CordaX500NameDto, PaymentDto, PaymentStatusEnum } from '../../../generated';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { NumberFormatUnitsText } from '../../assets/numberformat/NumberFormatUnits';
import { formatCordaX500Name, formatDateTimeToGermanStyle } from '../../../common/format';
import { PaymentDetails } from './PaymentDetails';
import { CustomTable } from '../../assets/tables/CustomTable';

export function PaymentsTable(props: { paymentsData: PaymentDto[]; promiseInProgress: boolean }): JSX.Element {
  // Trnaslations
  const { t } = useTranslation();
  const unitTxt = t('unit');
  const amountTxt = t('amount');
  const timestampTxt = t('timestamps');
  const lastUpdateTxt = t('lastUpdate');
  const machineOwnerTxt = t('machineOwner');
  const machineUserTxt = t('machineUser');
  const machineNameTxt = t('machineName');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: { id: keyof PaymentDto; label: string; render?: (value: any) => React.ReactNode }[] = [
    {
      id: 'created',
      label: timestampTxt,
      render: (value: Date | undefined) => (value ? formatDateTimeToGermanStyle(value) : ''),
    },
    { id: 'machineOwner', label: machineOwnerTxt, render: (value: CordaX500NameDto) => formatCordaX500Name(value) },
    {
      id: 'machineUser',
      label: machineUserTxt,
      render: (value: CordaX500NameDto) => formatCordaX500Name(value),
    },
    {
      id: 'machineName',
      label: machineNameTxt,
    },
    {
      id: 'totalUnits',
      label: unitTxt,
      render: (value) => <NumberFormatUnitsText value={value} />,
    },
    {
      id: 'totalAmount',
      label: amountTxt,
      render: (value) => <NumberFormatEurText value={value} />,
    },
    {
      id: 'paymentStatus',
      label: 'Status',
      render: (value) => <PaymentStatusChip paymentStatus={value} />,
    },
    {
      id: 'updated',
      label: lastUpdateTxt,
      render: (value: Date | undefined) => (value ? formatDateTimeToGermanStyle(value) : ''),
    },
  ];

  function getRowId(row: PaymentDto): string {
    return row.paymentId;
  }

  function handleExpandRow(row: PaymentDto) {
    return <PaymentDetails payment={row} />;
  }

  return (
    <CustomTable
      data={props.paymentsData}
      columns={columns}
      promiseInProgress={props.promiseInProgress}
      initialOrderBy="created"
      getRowId={getRowId}
      onExpandRow={handleExpandRow}
    />
  );
}

// Helper Components
function PaymentStatusChip({ paymentStatus }: { paymentStatus: PaymentStatusEnum }) {
  let chipColor: 'green' | 'orange' | 'grey' = 'grey';
  let paymentStatusLabel = '';

  // Trnaslations
  const { t } = useTranslation();
  const pendingTxt = t('paymentsTable.pending');
  const initiatedTxt = t('paymentsTable.initiated');
  const confirmedTxt = t('paymentsTable.confirmed');

  switch (paymentStatus) {
    case PaymentStatusEnum.Initiated:
      chipColor = 'grey';
      paymentStatusLabel = initiatedTxt;
      break;
    case PaymentStatusEnum.Pending:
      chipColor = 'orange';
      paymentStatusLabel = pendingTxt;
      break;
    case PaymentStatusEnum.Confirmed:
      chipColor = 'green';
      paymentStatusLabel = confirmedTxt;
      break;
  }

  return <ColorChip chipColor={chipColor} size="small" label={paymentStatusLabel} />;
}
