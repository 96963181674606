import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCordaX500Name, formatDateTimeToGermanStyle } from '../../../common/format';
import { CordaX500NameDto, InsuranceDto, InsuranceStatusEnum } from '../../../generated';
import { CustomTable } from '../../assets/tables/CustomTable';
import { InsuranceDetails } from './InsuranceDetails';
import { InsuranceDialogState } from './InsuranceDialog';
import { useConfig } from '../../../common/config';
import InsuranceStatusChip from './InsuranceStatusChip';

export function InsurancesTable(props: {
  insurancesData: InsuranceDto[];
  promiseInProgress: boolean;
  setEditInsuranceDialog: (open: { open: InsuranceDialogState; insurance: InsuranceDto }) => void;
}): JSX.Element {
  const { insurancePage } = useConfig();

  // Trnaslations
  const { t } = useTranslation();
  const createdTxt = t('created');
  const updatedTxt = t('updated');
  const insurerTxt = t('insurer');
  const machineNameTxt = t('machineName');
  const counterPartyTxt = t('insurance.counterParty');
  const insuranceNumberTxt = t('insurance.insuranceNumber');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: { id: keyof InsuranceDto; label: string; render?: (value: any) => React.ReactNode }[] = [
    { id: 'insuranceExternalId', label: insuranceNumberTxt },
    { id: 'machineName', label: machineNameTxt },
    {
      id: 'insurer',
      label: insurerTxt,
      render: (value: CordaX500NameDto) => formatCordaX500Name(value),
    },
    {
      id: 'counterparty',
      label: counterPartyTxt,
      render: (value: CordaX500NameDto) => formatCordaX500Name(value),
    },
    {
      id: 'insuranceStatus',
      label: 'Status',

      render: (value: InsuranceStatusEnum | undefined) => <InsuranceStatusChip insuranceStatus={value} size="small" />,
    },
    {
      id: 'created',
      label: createdTxt,
      render: (value: Date | undefined) => (value ? formatDateTimeToGermanStyle(value) : ''),
    },
    {
      id: 'updated',
      label: updatedTxt,
      render: (value: Date | undefined) => (value ? formatDateTimeToGermanStyle(value) : ''),
    },
  ];

  function getRowId(row: InsuranceDto): string {
    return row.insuranceExternalId;
  }

  function handleEdit(row: InsuranceDto) {
    props.setEditInsuranceDialog({
      open: InsuranceDialogState.InsuranceBasicDialog,
      insurance: row,
    });
  }

  function handleExpandRow(row: InsuranceDto) {
    return <InsuranceDetails insurance={row} setEditInsuranceDialog={props.setEditInsuranceDialog} />;
  }

  return (
    <CustomTable
      data={props.insurancesData}
      columns={columns}
      promiseInProgress={props.promiseInProgress}
      canEdit={insurancePage.canEditInsurances}
      initialOrderBy="created"
      getRowId={getRowId}
      onEdit={handleEdit}
      onExpandRow={handleExpandRow}
    />
  );
}
