import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

import { Title } from '../../assets/Title';
/**
 * Definition of the about view component.
 */
export function AboutPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t('aboutPage.title')}</Title>
      <br />
      <Typography variant="body1">{t('aboutPage.security')}</Typography>
      <br />
      <Typography variant="body2">{t('aboutPage.flexibility')}</Typography>
      <Typography variant="body2">{t('aboutPage.investment')}</Typography>
      <p>
        <Link href="mailto:info@payperchain.com"> {t('aboutPage.contactUs')}</Link>
      </p>
      <Typography variant="body2">
        <Link href="https://www.payperchain.com/">PayperChain GmbH</Link> | Vor dem Langen Loh 6 | 35075
        Gladenbach-Mornshausen
      </Typography>

      {/* Credits Section */}
      <Box sx={{ mt: 4 }}>
        <Title>{t('aboutPage.iconCredits')}</Title>
        <Typography variant="caption" display="block" sx={{ mt: 2 }}>
          {t('aboutPage.IconsFrom')}{' '}
          <Link href="https://www.flaticon.com" target="_blank" rel="noopener noreferrer">
            flaticon.com
          </Link>
          {`, ${t('aboutPage.creditsTo')} `}
          <Link href="https://www.flaticon.com/authors/cah-nggunung" target="_blank" rel="noopener noreferrer">
            cah nggug
          </Link>
          .
        </Typography>
      </Box>
    </React.Fragment>
  );
}
