import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Box,
  TableSortLabel,
  TablePagination,
  Collapse,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';

import { NumberFormatEurText } from '../../../assets/numberformat/NumberFormatEur';
import InsuranceInvoiceStatusChip from './InsuranceInvoiceStatusChip';
import { InsuranceInvoiceDetailsDto, InsuranceInvoiceDto } from '../../../../generated';
import { InsuranceInvoiceDetails } from './InsuranceInvoiceDetails';
import { useConfig } from '../../../../common/config';
import { formatDateTimeToGermanStyle } from '../../../../common/format';
import Spinner from '../../../assets/Spinner';

type Order = 'asc' | 'desc';

// if the input was such array [2, 4, 1, 5, 3], then the descending order result is  [5, 4, 3, 2, 1]
function descendingComparator<T>(a: T, b: T, orderBy: keyof InsuranceInvoiceDto | keyof InsuranceInvoiceDetailsDto) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let aValue = (a as any)[orderBy];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let bValue = (b as any)[orderBy];

  // If orderBy is in invoiceDetails, extract it
  if (orderBy in (a as InsuranceInvoiceDto).invoiceDetails) {
    aValue = (a as InsuranceInvoiceDto).invoiceDetails[orderBy as keyof InsuranceInvoiceDetailsDto];
    bValue = (b as InsuranceInvoiceDto).invoiceDetails[orderBy as keyof InsuranceInvoiceDetailsDto];
  }

  // Convert Date fields to timestamps
  if (aValue instanceof Date) aValue = aValue.getTime();
  if (bValue instanceof Date) bValue = bValue.getTime();

  if (bValue < aValue) return -1;
  if (bValue > aValue) return 1;
  return 0;
}

function getComparator(
  order: Order,
  orderBy: keyof InsuranceInvoiceDto | keyof InsuranceInvoiceDetailsDto,
): (a: InsuranceInvoiceDto, b: InsuranceInvoiceDto) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function InsuranceInvoicesTable(props: {
  insurancesData: InsuranceInvoiceDto[];
  promiseInProgress: boolean;
  setEditInsuranceInvoiceDialog: (open: { open: boolean; insuranceInvoice: InsuranceInvoiceDto }) => void;
}): JSX.Element {
  const { insurancesData, promiseInProgress, setEditInsuranceInvoiceDialog } = props;
  const { insurancePage } = useConfig();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof InsuranceInvoiceDto | keyof InsuranceInvoiceDetailsDto>('periodStart');
  const [filterText, setFilterText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});
  const tableCell = {
    minWidth: 100,
    maxWidth: 300,
    whiteSpace: 'normal', // Allow text wrapping based on the content
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
  };

  // Translations
  const { t } = useTranslation();
  const editTxt = t('edit');
  const searchTxt = t('search');
  const ppuAmountTxt = t('insurance.ppuAmount');
  const periodEndTxt = t('insurance.periodEnd');
  const baseAmountTxt = t('insurance.baseAmount');
  const periodStartTxt = t('insurance.periodStart');
  const invoiceAmountTxt = t('insurance.invoiceAmount');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');
  const rowsPerPageTxt = t('tableToolbar.rowsPerPage');

  // Table Head Data
  const tableHeads = [
    { id: 'periodStart', label: periodStartTxt },
    { id: 'periodEnd', label: periodEndTxt },
    { id: 'baseAmount', label: baseAmountTxt },
    { id: 'payPerUseAmount', label: ppuAmountTxt },
    { id: 'invoiceAmount', label: invoiceAmountTxt },
    { id: 'invoiceStatus', label: 'Status' },
  ];

  const handleToggleRow = (invoiceId: string) => {
    setOpenRows((prev) => ({
      ...prev,
      [invoiceId]: !prev[invoiceId], // Toggle only the clicked row
    }));
  };

  // `filteredData` is a filtered version of `insurancesData` that enables the search functionality.
  // It checks if the `filterText` is present in any of the relevant fields, including formatted dates, amounts, and status.
  // Only rows that match the search query are included in `filteredData`, ensuring that the table updates dynamically based on user input.
  const filteredData = insurancesData.filter(
    (row) =>
      formatDateTimeToGermanStyle(row.invoiceDetails?.periodStart)?.toLowerCase().includes(filterText.toLowerCase()) ||
      formatDateTimeToGermanStyle(row.invoiceDetails?.periodEnd)?.toLowerCase().includes(filterText.toLowerCase()) ||
      row.invoiceDetails?.baseAmount?.toString().includes(filterText) ||
      row.invoiceDetails?.payPerUseAmount?.toString().includes(filterText) ||
      row.invoiceDetails?.invoiceAmount?.toString().includes(filterText) ||
      row.invoiceStatus.toString().toLowerCase().includes(filterText.toLowerCase()),
  );

  // Sort & Paginate Data
  const sortedAndPaginatedData = useMemo(
    () =>
      [...filteredData].sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredData, order, orderBy, page, rowsPerPage],
  );

  const handleEdit = (insuranceInvoice: InsuranceInvoiceDto) => {
    setEditInsuranceInvoiceDialog({ open: true, insuranceInvoice });
  };

  const handleSortRequest = (property: keyof InsuranceInvoiceDto | keyof InsuranceInvoiceDetailsDto) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - insurancesData.length) : 0;

  return (
    <Box sx={{ width: '100%', mt: '0.5rem' }}>
      <TextField
        variant="outlined"
        fullWidth
        placeholder={searchTxt}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        sx={{ mb: 2 }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
      {promiseInProgress ? (
        <Spinner />
      ) : (
        <TableContainer sx={{ overflowY: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {insurancesData && <TableCell />}
                {tableHeads.map((head) => (
                  <TableCell sx={tableCell} key={head.id} align="left">
                    <TableSortLabel
                      active={orderBy === head.id}
                      direction={orderBy === head.id ? order : 'asc'}
                      onClick={() =>
                        handleSortRequest(head.id as keyof InsuranceInvoiceDto | keyof InsuranceInvoiceDetailsDto)
                      }
                    >
                      {head.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {insurancePage.canEditInsurances && <TableCell align="center">{editTxt}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAndPaginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Box
                      sx={{
                        height: '40vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography>{noRecordsToDisplayTxt}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                sortedAndPaginatedData.map((row) => (
                  <React.Fragment key={row.invoiceId}>
                    <TableRow>
                      <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => handleToggleRow(row.invoiceId)}>
                          {openRows[row.invoiceId] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell sx={tableCell} align="left">
                        {formatDateTimeToGermanStyle(row.invoiceDetails.periodStart)}
                      </TableCell>
                      <TableCell sx={tableCell} align="left">
                        {formatDateTimeToGermanStyle(row.invoiceDetails.periodEnd)}
                      </TableCell>
                      <TableCell sx={tableCell} align="left">
                        <NumberFormatEurText value={row.invoiceDetails.baseAmount} />
                      </TableCell>
                      <TableCell sx={tableCell} align="left">
                        <NumberFormatEurText value={row.invoiceDetails.payPerUseAmount} />
                      </TableCell>
                      <TableCell sx={tableCell} align="left">
                        <NumberFormatEurText value={row.invoiceDetails.invoiceAmount} />
                      </TableCell>
                      <TableCell sx={tableCell} align="left">
                        <InsuranceInvoiceStatusChip insuranceInvoiceStatus={row.invoiceStatus} size="small" />
                      </TableCell>
                      {insurancePage.canEditInsurances && (
                        <TableCell align="center">
                          <IconButton onClick={() => handleEdit(row)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={!!openRows[row.invoiceId]} timeout="auto" unmountOnExit>
                          <Box sx={{ marginY: 2 }}>
                            <InsuranceInvoiceDetails insuranceInvoice={row} />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 70 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={insurancesData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={rowsPerPageTxt}
      />
    </Box>
  );
}
