import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import RefreshIcon from '@mui/icons-material/Refresh';

import { PaymentDto, ResponseError } from '../../../generated';
import useNotifications from '../../assets/useNotifications';
import { getPaymentsApi } from '../../../common/keycloak';
import { PaymentsTable } from './PaymentsTable';
import { Title } from '../../assets/Title';
import { useStyles } from '../../Styles';

/**
 * Definition of the payments overview component.
 */
export function PaymentsPage(): JSX.Element {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'payments', delay: 200 });

  const [paymentsData, setPaymentsData] = useState(new Array<PaymentDto>());
  const [reloadData, setReloadData] = useState('');

  const { showError } = useNotifications();

  // Trnaslations
  const { t } = useTranslation();
  const reloadTxt = t('reload');
  const paymentsTxt = t('payments');
  const userUnauthorizedTxt = t('userUnauthorized');
  const errorFetchingDataTxt = t('errorFetchingData');

  /** fetch data*/
  useEffect(() => {
    const loadPaymentsData = async () => {
      const api = await getPaymentsApi();
      try {
        const result = await api.getPayments();
        setPaymentsData(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError(userUnauthorizedTxt);
          } else {
            showError(error.message);
          }
        } else {
          showError(errorFetchingDataTxt);
        }
      }
    };
    trackPromise(loadPaymentsData(), 'payments');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, showError]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid size={{ xs: 12 }} style={{ flex: 1 }}>
          <Title>{paymentsTxt}</Title>
        </Grid>
        <Grid size={{ xs: 6 }} className={classes.buttonRight}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<RefreshIcon>reload</RefreshIcon>}
            onClick={() => setReloadData(reloadData + 1)}
          >
            {reloadTxt}
          </Button>
        </Grid>
      </Grid>
      <PaymentsTable paymentsData={paymentsData} promiseInProgress={promiseInProgress} />
    </React.Fragment>
  );
}
