import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { NumberFormatEurText } from './numberformat/NumberFormatEur';
import { MachineActivityDto, MachineActivityStatusEnum } from '../../generated';
import { formatDateTimeToGermanStyle } from '../../common/format';
import ColorChip from './ColorChip';
import { CustomTable } from './tables/CustomTable';
import { NumberFormatUnitsText } from './numberformat/NumberFormatUnits';

export function MachineActivityTable(props: {
  machineActivities: MachineActivityDto[];
  promiseInProgress: boolean;
}): JSX.Element {
  const rowIdMap = new WeakMap<MachineActivityDto, string>();

  const getRowId = (row: MachineActivityDto) => {
    if (!rowIdMap.has(row)) {
      rowIdMap.set(row, uuidv4());
    }
    return rowIdMap.get(row) as string;
  };

  // Trnaslations
  const { t } = useTranslation();
  const paidTxt = t('paid');
  const unpaidTxt = t('unpaid');
  const pricePerUnitTxt = t('pricePerUnit');
  const activityCostTxt = t('machineActivityTable.activityCost');
  const activityNoteTxt = t('machineActivityTable.activityNote');
  const activityUnitsTxt = t('machineActivityTable.activityUnits');
  const activityStatusTxt = t('machineActivityTable.activityStatus');
  const activityTimestampTxt = t('machineActivityTable.activityTimestamp');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: { id: keyof MachineActivityDto; label: string; render?: (value: any) => React.ReactNode }[] = [
    {
      id: 'activityTimestamp',
      label: activityTimestampTxt,
      render: (value: Date | undefined) => (value ? formatDateTimeToGermanStyle(value) : ''),
    },
    { id: 'activityUnits', label: activityUnitsTxt, render: (value) => <NumberFormatUnitsText value={value} /> },
    { id: 'pricePerUnit', label: pricePerUnitTxt, render: (value) => <NumberFormatEurText value={value} /> },
    {
      id: 'activityCost',
      label: activityCostTxt,
      render: (value) => <NumberFormatEurText value={value} />,
    },
    {
      id: 'activityStatus',
      label: activityStatusTxt,
      render: (value: MachineActivityStatusEnum) => <MachineActivityStatusChip activityStatus={value} />,
    },
    {
      id: 'activityNote',
      label: activityNoteTxt,
    },
  ];

  // const testData: MachineActivityDto[] = [
  //   {
  //     activityTimestamp: new Date('2024-04-06T12:30:00'),
  //     activityUnits: 20,
  //     pricePerUnit: 4.75,
  //     activityCost: 95,
  //     activityStatus: MachineActivityStatusEnum.Unpaid,
  //     activityNote: 'April (4)',
  //   },
  //   {
  //     activityTimestamp: new Date('2024-02-22T11:30:00'),
  //     activityUnits: 11,
  //     pricePerUnit: 4,
  //     activityCost: 225,
  //     activityStatus: MachineActivityStatusEnum.Paid,
  //     activityNote: 'Feb (2)',
  //   },
  // ];

  // const mergedData = [...testData, ...props.machineActivities];

  // Shows a colorful badge for each status for each activity
  function MachineActivityStatusChip(props: { activityStatus: MachineActivityStatusEnum }) {
    let chipColor: 'green' | 'orange' = 'green';
    let activityStatusLabel = '';

    switch (props.activityStatus) {
      case MachineActivityStatusEnum.Paid: {
        chipColor = 'green';
        activityStatusLabel = paidTxt;
        break;
      }
      case MachineActivityStatusEnum.Unpaid: {
        chipColor = 'orange';
        activityStatusLabel = unpaidTxt;
        break;
      }
    }

    return <ColorChip chipColor={chipColor} size="small" label={activityStatusLabel} />;
  }

  return (
    <CustomTable
      data={props.machineActivities}
      columns={columns}
      promiseInProgress={props.promiseInProgress}
      initialOrder="desc"
      initialOrderBy="activityTimestamp"
      getRowId={getRowId}
    />
  );
}
