import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  TableSortLabel,
  TablePagination,
  Box,
  Button,
  Chip,
  CircularProgress,
} from '@mui/material';
import { Edit, Search as SearchIcon, KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import { FirstPage, LastPage } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { CordaX500NameDto, MembershipDto, MembershipRoleEnum, MembershipStatusEnum } from '../../../generated';
import { formatCordaX500Name, formatDateTimeToGermanStyle } from '../../../common/format';
import DataGridSkeleton from '../../assets/DataGridSkeleton';
import useNotifications from '../../assets/useNotifications';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getMembershipsApi } from '../../../common/keycloak';
import { useConfig } from '../../../common/config';
import MemberStatusChip from './MemberStatusChip';

type Order = 'asc' | 'desc';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MembersTable(props: {
  memberships: MembershipDto[];
  loading: boolean;
  setEditMembershipDialog: (open: { open: boolean; membership: MembershipDto }) => void;
  reload: () => void;
}) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof MembershipDto>('identity');
  const [filterText, setFilterText] = useState('');
  const [page, setPage] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { membersPage } = useConfig();
  const { dashboard } = useConfig();
  const tableCell = {
    minWidth: 100,
    maxWidth: 300,
    whiteSpace: 'normal', // Allow text wrapping based on the content
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
  };
  const noRecords = {
    height: '40vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  // Trnaslations
  const { t } = useTranslation();
  const editTxt = t('edit');
  const searchTxt = t('search');
  const rowsPerPageTxt = t('tableToolbar.rowsPerPage');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');
  const rolesTxt = t('roles');
  const createdTxt = t('created');
  const insurerTxt = t('insurer');
  const updatedTxt = t('updated');
  const machineUserTxt = t('machineUser');
  const machineOwnerTxt = t('machineOwner');
  const identityTxt = t('membersTable.identity');
  const machineObserverTxt = t('machineObserver');
  const paymentProviderTxt = t('paymentProvider');

  // Table Head Data
  const tableHeads = [
    { id: 'identity', label: identityTxt },
    { id: 'membershipStatus', label: 'Status' },
    { id: 'membershipRoles', label: rolesTxt },
    { id: 'created', label: createdTxt },
    { id: 'updated', label: updatedTxt },
  ];

  // Function to make sure that only the BNO can see suspended participants
  function filterSuspendedParticipantsForBNO(rows: MembershipDto[]): MembershipDto[] {
    return rows.filter((row) => {
      const filtered =
        row.membershipStatus === MembershipStatusEnum.Suspended ||
        row.membershipStatus === MembershipStatusEnum.Pending;

      // If current user is a BNO, include all rows
      if (isBno()) {
        return true;
      }

      // If current user is not a BNO, show only ACTIVE participants
      return !filtered;
    });
  }

  function isBno(): boolean {
    return props.memberships.some((ms) => {
      return (
        ms.membershipRoles.includes(MembershipRoleEnum.Bno) &&
        ms.identity.organization.toLowerCase() === dashboard.profile.toLowerCase()
      );
    });
  }
  const filteredRowsForBNO = filterSuspendedParticipantsForBNO(props.memberships || []);
  const memberships = filteredRowsForBNO ?? [];

  function handleSortRequest(property: keyof MembershipDto) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const filteredData = memberships.filter(
    (row) =>
      formatCordaX500Name(row.identity).toLowerCase().includes(filterText.toLowerCase()) ||
      row.membershipStatus.toLowerCase().includes(filterText.toLowerCase()) ||
      row.membershipRoles.some((role) =>
        getMembershipRoleLabel(role).toLowerCase().includes(filterText.toLowerCase()),
      ) ||
      formatDateTimeToGermanStyle(row.created).toLowerCase().includes(filterText.toLowerCase()) ||
      formatDateTimeToGermanStyle(row.updated).toLowerCase().includes(filterText.toLowerCase()),
  );

  function getMembershipRoleLabel(membershipRole: MembershipRoleEnum): string {
    switch (membershipRole) {
      case MembershipRoleEnum.Bno:
        return 'BNO';
      case MembershipRoleEnum.MachineOwner:
        return 'Machine Owner';
      case MembershipRoleEnum.MachineUser:
        return 'Machine User';
      case MembershipRoleEnum.PaymentProvider:
        return 'Payment Provider';
      case MembershipRoleEnum.Insurer:
        return 'Insurer';
      case MembershipRoleEnum.MachineObserver:
        return 'Machine Observer';
      default:
        return membershipRole;
    }
  }

  // Membership roles
  function MembershipRoleChip(props: { membershipRole: MembershipRoleEnum }) {
    const theme = useTheme();
    let label;
    switch (props.membershipRole) {
      case MembershipRoleEnum.Bno: {
        label = 'BNO';
        break;
      }
      case MembershipRoleEnum.MachineOwner: {
        label = machineOwnerTxt;
        break;
      }
      case MembershipRoleEnum.MachineUser: {
        label = machineUserTxt;
        break;
      }
      case MembershipRoleEnum.PaymentProvider: {
        label = paymentProviderTxt;
        break;
      }
      case MembershipRoleEnum.Insurer: {
        label = insurerTxt;
        break;
      }
      case MembershipRoleEnum.MachineObserver: {
        label = machineObserverTxt;
        break;
      }
      default: {
        label = props.membershipRole;
        break;
      }
    }

    return <Chip size="small" label={label} style={{ margin: theme.spacing(0.25) }} />;
  }

  function parseDate(value: unknown): number {
    if (value instanceof Date) {
      return value.getTime();
    } else if (typeof value === 'string' && !isNaN(Date.parse(value))) {
      return new Date(value).getTime();
    }
    return 0;
  }

  // Sorting
  const sortedData = useMemo(() => {
    if (!orderBy) return filteredData;
    return [...filteredData].sort((a, b) => {
      const aValue = a[orderBy] ?? '';
      const bValue = b[orderBy] ?? '';

      // Sort dates
      if (typeof orderBy === 'string' && ['updated', 'created'].includes(orderBy)) {
        const aDate = parseDate(aValue);
        const bDate = parseDate(bValue);
        return order === 'asc' ? aDate - bDate : bDate - aDate;
      }

      // Sort CordaX500NameDto (identity)
      if (orderBy === 'identity' && typeof aValue === 'object' && !Array.isArray(aValue)) {
        const formattedAValue = formatCordaX500Name(aValue as CordaX500NameDto);
        const formattedBValue = formatCordaX500Name(bValue as CordaX500NameDto);
        return order === 'asc'
          ? formattedAValue.localeCompare(formattedBValue)
          : formattedBValue.localeCompare(formattedAValue);
      }

      // Sort strings
      return order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  }, [filteredData, order, orderBy]);

  // Pagination
  const paginatedData = useMemo(
    () => sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [sortedData, page, rowsPerPage],
  );

  function handleChangePage(_: unknown, newPage: number): void {
    setPage(newPage);
    setActivePage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleEdit(row: MembershipDto) {
    props.setEditMembershipDialog({ open: true, membership: row });
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - memberships.length) : 0;

  // Handle empty state and loading state
  const EmptyDataInformation = function () {
    if (!memberships) {
      return (
        <Box sx={noRecords}>
          <Typography>{noRecordsToDisplayTxt}</Typography>
        </Box>
      );
    }

    if (memberships.length === 0) {
      if (membersPage.canCreateBusinessNetwork) {
        return <CreateBusinessNetworkInformation onSuccess={props.reload ?? (() => {})} />;
      }
      return <RequestMembershipInformation onSuccess={props.reload ?? (() => {})} />;
    }

    return (
      <Box sx={noRecords}>
        <Typography>{noRecordsToDisplayTxt}</Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (filterText) {
      setPage(0);
    } else {
      setPage(activePage);
    }
  }, [filterText, activePage]);

  return (
    <Box sx={{ width: '100%', mt: '0.5rem' }}>
      <TextField
        variant="outlined"
        fullWidth
        placeholder={searchTxt}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        sx={{ mb: 2 }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
      {props.loading ? (
        <DataGridSkeleton />
      ) : (
        <>
          <TableContainer sx={{ overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeads.map((head) => (
                    <TableCell sx={tableCell} key={head.id} align="left">
                      <TableSortLabel
                        active={orderBy === head.id}
                        direction={orderBy === head.id ? order : 'asc'}
                        onClick={() => handleSortRequest(head.id as keyof MembershipDto)}
                      >
                        {head.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {membersPage.canEditMembers && <TableCell align="center">{editTxt}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="body2">
                        <EmptyDataInformation />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedData.map((row) => {
                    return (
                      <React.Fragment key={row.membershipId}>
                        <TableRow>
                          <TableCell sx={tableCell} align="left">
                            {formatCordaX500Name(row.identity)}
                          </TableCell>
                          <TableCell sx={tableCell} align="left">
                            <MemberStatusChip membershipStatus={row.membershipStatus} size="small" />
                          </TableCell>
                          <TableCell sx={tableCell} align="left">
                            {row.membershipRoles.map((role: MembershipRoleEnum, index: number) => (
                              <MembershipRoleChip key={index} membershipRole={role} />
                            ))}
                          </TableCell>
                          <TableCell sx={tableCell} align="left">
                            {formatDateTimeToGermanStyle(row.created)}
                          </TableCell>
                          <TableCell sx={tableCell} align="left">
                            {formatDateTimeToGermanStyle(row.updated)}{' '}
                          </TableCell>

                          {membersPage.canEditMembers && (
                            <TableCell align="center">
                              <IconButton onClick={() => handleEdit(row)}>
                                <Edit />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                )}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 70 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={memberships.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage={rowsPerPageTxt}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      />
    </Box>
  );
}

// Function to render custom table pagination
function TablePaginationActions({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) {
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* First Page Button */}
      <IconButton onClick={(event) => onPageChange(event, 0)} disabled={page === 0}>
        <FirstPage />
      </IconButton>

      {/* Previous Page Button */}
      <IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>

      {/* Next Page Button */}
      <IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= lastPage}>
        <KeyboardArrowRight />
      </IconButton>

      {/* Last Page Button */}
      <IconButton onClick={(event) => onPageChange(event, lastPage)} disabled={page >= lastPage}>
        <LastPage />
      </IconButton>
    </Box>
  );
}

// All the following methods are only used for the MembersTable

/**
 * Info that is shown to request membership
 */
function RequestMembershipInformation(props: { onSuccess: () => void }): JSX.Element {
  const { showError, showSuccess } = useNotifications();

  const { promiseInProgress } = usePromiseTracker({ area: 'membership-request', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const membershipRequestedSuccessTxt = t('membersTable.membershipRequestedSuccess');
  const membershipRequestedErrorTxt = t('membersTable.membershipRequestedError');
  const membershipRequestedErrorStatusTxt = t('membersTable.membershipRequestedErrorStatus');
  const networkMembershipTxt = t('membersTable.networkMembership');
  const requestMembershipTxt = t('membersTable.requestMembership');

  const handleClick = async function () {
    // Call MemberApi to request membership
    const requestMembership = async () => {
      const api = await getMembershipsApi();
      return api.requestMembership();
    };

    try {
      const result = await trackPromise(requestMembership(), 'membership-request');

      if (result) {
        showSuccess(membershipRequestedSuccessTxt);
        props.onSuccess();
      } else {
        showError(membershipRequestedErrorTxt);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`${membershipRequestedErrorStatusTxt} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  return (
    <NoNetworkInformation
      onClick={handleClick}
      promiseInProgress={promiseInProgress}
      description={networkMembershipTxt}
      label={requestMembershipTxt}
    />
  );
}

/**
 * Info that is shown to create a new business network
 */
function CreateBusinessNetworkInformation(props: { onSuccess: () => void }) {
  const { showError, showSuccess } = useNotifications();

  const { promiseInProgress } = usePromiseTracker({ area: 'membership-request', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const networkCreateSuccessTxt = t('membersTable.networkCreateSuccess');
  const networkCreateErrorTxt = t('membersTable.networkCreateError');
  const networkCreateErrorStatusTxt = t('membersTable.networkCreateErrorStatus');
  const networkExistanceTxt = t('membersTable.networkExistance');
  const createNetworkTxt = t('membersTable.createNetwork');

  const handleClick = async function () {
    // Call MemberApi to create business network
    const createBusinessNetwork = async () => {
      const api = await getMembershipsApi();
      return api.createBusinessNetwork();
    };

    try {
      const result = await trackPromise(createBusinessNetwork(), 'membership-request');

      if (result) {
        showSuccess(networkCreateSuccessTxt);
        props.onSuccess();
      } else {
        showError(networkCreateErrorTxt);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`${networkCreateErrorStatusTxt} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  return (
    <NoNetworkInformation
      onClick={handleClick}
      promiseInProgress={promiseInProgress}
      description={networkExistanceTxt}
      label={createNetworkTxt}
    />
  );
}

/**
 * Info that is shown when no business network is found
 */
function NoNetworkInformation(props: {
  onClick: () => void;
  promiseInProgress: boolean;
  label: string;
  description: string;
}) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <Typography>{props.description}</Typography>
      </Grid>
      <Grid>
        <Button variant="contained" color="primary" onClick={props.onClick} disabled={props.promiseInProgress}>
          {props.promiseInProgress && <CircularProgress size={22} color="secondary" style={{ marginRight: 10 }} />}
          {props.label}
        </Button>
      </Grid>
    </Grid>
  );
}
