import { trackPromise } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText } from '@mui/material';

import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { formatCordaX500Name } from '../../../common/format';
import { getNetworkApi } from '../../../common/keycloak';
import { CordaX500NameDto, MachineDto } from '../../../generated';
import { useConfig } from '../../../common/config';
import { MachineDetails } from './MachineDetails';
import { CustomTable } from '../../assets/tables/CustomTable';

export function MachinesTable(props: {
  machinesData: MachineDto[];
  promiseInProgress: boolean;
  setEditMachineDialog: (open: { open: boolean; machine: MachineDto }) => void;
}): JSX.Element {
  const { machinesPage } = useConfig();
  const [myIdentity, setMyIdentity] = useState('');

  // Trnaslations
  const { t } = useTranslation();
  const insurersTxt = t('insurers');
  const machineOwnerTxt = t('machineOwner');
  const machineUserTxt = t('machineUser');
  const machineNameTxt = t('machineName');
  const pricePerUnitTxt = t('pricePerUnit');
  const paymentProviderTxt = t('paymentProvider');
  const paymentThresholdTxt = t('paymentThreshold');

  // const dummyMachineOwners: CordaX500NameDto[] = [
  //   {
  //     organization: 'Global Heavy Machinery',
  //     locality: 'Los Angeles',
  //     country: 'US',
  //   },
  //   {
  //     organization: 'Titan Equipment Rentals',
  //     locality: 'Toronto',
  //     country: 'CA',
  //   },
  //   { organization: 'MegaBuild Ltd.', locality: 'London', country: 'UK' },
  // ];

  // const dummyMachineProviders: CordaX500NameDto[] = [
  //   {
  //     organization: 'Industrial Equipment Solutions',
  //     locality: 'Chicago',
  //     country: 'US',
  //   },
  //   { organization: 'Machinery Depot', locality: 'Berlin', country: 'DE' },
  //   {
  //     organization: 'TechGear Manufacturing',
  //     locality: 'Tokyo',
  //     country: 'JP',
  //   },
  // ];

  // const dummyMachineUsers: CordaX500NameDto[] = [
  //   {
  //     organization: 'Michael Smith Construction',
  //     locality: 'Houston',
  //     country: 'US',
  //   },
  //   {
  //     organization: 'Greenfield Contractors',
  //     locality: 'Sydney',
  //     country: 'AU',
  //   },
  //   {
  //     organization: 'Bridge & Co. Builders',
  //     locality: 'Mumbai',
  //     country: 'IN',
  //   },
  // ];

  // const dummyInsurers: CordaX500NameDto[] = [{ organization: 'Allianz', locality: 'Berlin', country: 'DE' }];

  // const testData: MachineDto[] = [
  //   {
  //     machineId: 'M001',
  //     created: new Date('2024-01-01T08:00:00'),
  //     updated: new Date('2024-01-05T09:00:00'),
  //     machineOwner: dummyMachineOwners[0],
  //     machineUser: dummyMachineUsers[0],
  //     machineName: 'Excavator 3000',
  //     pricePerUnit: 12.99,
  //     paymentThreshold: 500,
  //     paymentProvider: dummyMachineProviders[1],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  //   {
  //     machineId: 'M002',
  //     created: new Date('2024-02-10T10:15:00'),
  //     updated: new Date('2024-02-15T11:30:00'),
  //     machineOwner: dummyMachineOwners[1],
  //     machineUser: dummyMachineUsers[1],
  //     machineName: 'Bulldozer X',
  //     pricePerUnit: 15.5,
  //     paymentThreshold: 600,
  //     paymentProvider: dummyMachineProviders[0],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  //   {
  //     machineId: 'M003',
  //     created: new Date('2024-03-05T12:20:00'),
  //     updated: new Date('2024-03-10T14:45:00'),
  //     machineOwner: dummyMachineOwners[2],
  //     machineUser: dummyMachineUsers[2],
  //     machineName: 'Crane UltraLift',
  //     pricePerUnit: 20.75,
  //     paymentThreshold: 700,
  //     paymentProvider: dummyMachineProviders[0],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  //   {
  //     machineId: 'M004',
  //     created: new Date('2024-04-01T07:00:00'),
  //     updated: new Date('2024-04-07T08:30:00'),
  //     machineOwner: dummyMachineOwners[0],
  //     machineUser: dummyMachineUsers[1],
  //     machineName: 'Forklift Pro',
  //     pricePerUnit: 10.99,
  //     paymentThreshold: 400,
  //     paymentProvider: dummyMachineProviders[2],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  //   {
  //     machineId: 'M005',
  //     created: new Date('2024-05-12T09:40:00'),
  //     updated: new Date('2024-05-18T10:50:00'),
  //     machineOwner: dummyMachineOwners[1],
  //     machineUser: dummyMachineUsers[2],
  //     machineName: 'Concrete Mixer 5000',
  //     pricePerUnit: 18.25,
  //     paymentThreshold: 650,
  //     paymentProvider: dummyMachineProviders[1],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  //   {
  //     machineId: 'M006',
  //     created: new Date('2024-06-22T11:10:00'),
  //     updated: new Date('2024-06-28T13:15:00'),
  //     machineOwner: dummyMachineOwners[2],
  //     machineUser: dummyMachineUsers[0],
  //     machineName: 'Drill Master 9000',
  //     pricePerUnit: 22.49,
  //     paymentThreshold: 750,
  //     paymentProvider: dummyMachineProviders[2],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  //   {
  //     machineId: 'M007',
  //     created: new Date('2024-07-15T14:00:00'),
  //     updated: new Date('2024-07-20T15:45:00'),
  //     machineOwner: dummyMachineOwners[0],
  //     machineUser: dummyMachineUsers[2],
  //     machineName: 'Paver Elite',
  //     pricePerUnit: 19.99,
  //     paymentThreshold: 550,
  //     paymentProvider: dummyMachineProviders[1],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  //   {
  //     machineId: 'M008',
  //     created: new Date('2024-08-30T16:25:00'),
  //     updated: new Date('2024-09-05T17:35:00'),
  //     machineOwner: dummyMachineOwners[1],
  //     machineUser: dummyMachineUsers[0],
  //     machineName: 'Road Roller 700',
  //     pricePerUnit: 17.89,
  //     paymentThreshold: 600,
  //     paymentProvider: dummyMachineProviders[0],
  //     insurers: dummyInsurers,
  //     machineObservers: [],
  //   },
  // ];

  // const mergedData = [...props.machinesData, ...testData];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: { id: keyof MachineDto; label: string; render?: (value: any) => React.ReactNode }[] = [
    { id: 'machineName', label: machineNameTxt },
    { id: 'pricePerUnit', label: pricePerUnitTxt, render: (value) => <NumberFormatEurText value={value} /> },
    {
      id: 'paymentThreshold',
      label: paymentThresholdTxt,
      render: (value) => <NumberFormatEurText value={value} />,
    },
    {
      id: 'machineOwner',
      label: machineOwnerTxt,
      render: (value: CordaX500NameDto) => formatCordaX500Name(value),
    },
    {
      id: 'machineUser',
      label: machineUserTxt,
      render: (value: CordaX500NameDto) => formatCordaX500Name(value),
    },
    {
      id: 'paymentProvider',
      label: paymentProviderTxt,
      render: (value: CordaX500NameDto) => formatCordaX500Name(value),
    },
    {
      id: 'insurers',
      label: insurersTxt,
      render: (value: CordaX500NameDto[]) => {
        return (
          <List>
            {value &&
              value.map((insurer: CordaX500NameDto, index: number) => {
                return (
                  <ListItem dense disableGutters key={index + 10000}>
                    <ListItemText>{formatCordaX500Name(insurer)}</ListItemText>
                  </ListItem>
                );
              })}
          </List>
        );
      },
    },
  ];

  // Get my identity to show edit buttons only when machineOwner of machine is equal to it
  useEffect(() => {
    if (!machinesPage.canEditMachines) return;

    const getIdentity = async function () {
      const networkApi = await getNetworkApi();
      const currentNode = await networkApi.getActiveNode();

      setMyIdentity(formatCordaX500Name(currentNode.identity));
    };

    trackPromise(getIdentity(), 'my-identity');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getRowId(row: MachineDto): string {
    return row.machineId;
  }

  const userCanEdit =
    machinesPage.canEditMachines &&
    props.machinesData.some((machine) => myIdentity === formatCordaX500Name(machine.machineOwner));

  function handleEdit(row: MachineDto) {
    // Prevent editing if not the owner
    if (myIdentity !== formatCordaX500Name(row.machineOwner)) return;

    props.setEditMachineDialog({
      open: true,
      machine: row,
    });
  }

  function handleExpandRow(row: MachineDto) {
    return <MachineDetails machine={row} />;
  }

  return (
    <CustomTable
      data={props.machinesData}
      // data={mergedData}
      columns={columns}
      promiseInProgress={props.promiseInProgress}
      canEdit={userCanEdit}
      initialOrderBy="machineName"
      getRowId={getRowId}
      onEdit={handleEdit}
      onExpandRow={handleExpandRow}
    />
  );
}
