import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { Title } from '../../assets/Title';
import { useConfig } from '../../../common/config';
import { InsurancesTable } from './InsurancesTable';
import useNotifications from '../../assets/useNotifications';
import { getInsurancesApi } from '../../../common/keycloak';
import { InsuranceDto, ResponseError } from '../../../generated';
import InsuranceDialog, { InsuranceDialogState } from './InsuranceDialog';
import { useStyles } from '../../Styles';

/**
 * Definition of the component that displays price and invoice information for
 * every machine and lets the user change these parameters (depending on the
 * user role).
 */
export function InsurancesPage(): JSX.Element {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'insurances', delay: 200 });

  const [reloadData, setReloadData] = useState(0);
  const reload = () => {
    setReloadData(reloadData + 1);
  };

  interface EditInsuranceDialogData {
    open: InsuranceDialogState;
    insurance?: InsuranceDto;
  }
  const [editInsuranceDialog, setEditInsuranceDialog] = React.useState({
    open: InsuranceDialogState.None,
  } as EditInsuranceDialogData);

  const { showError } = useNotifications();

  const { insurancePage } = useConfig();

  // Trnaslations
  const { t } = useTranslation();
  const reloadTxt = t('reload');
  const insurancesTxt = t('insurance.insurances');
  const newInsuranceTxt = t('insurance.newInsurance');
  const userUnauthorizedTxt = t('userUnauthorized');
  const errorFetchingDataTxt = t('errorFetchingData');

  /** fetch data */
  const [insurancesData, setInsurancesData] = useState(new Array<InsuranceDto>());
  useEffect(() => {
    const loadInsurancesData = async () => {
      const api = await getInsurancesApi();
      try {
        const result = await api.getInsurances();
        setInsurancesData(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError(userUnauthorizedTxt);
          } else {
            showError(error.message);
          }
        } else {
          showError(errorFetchingDataTxt);
        }
      }
    };
    trackPromise(loadInsurancesData(), 'insurances');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, showError]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid size={{ xs: 12 }} style={{ flex: 1 }}>
          <Title>{insurancesTxt}</Title>
        </Grid>
        <Grid className={classes.buttonRight}>
          <Grid container spacing={2} justifyContent="flex-end" flexDirection="row">
            <Grid>
              <Button variant="outlined" color="primary" startIcon={<RefreshIcon />} onClick={reload}>
                {reloadTxt}
              </Button>
            </Grid>
            {insurancePage.canEditInsurances && (
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setEditInsuranceDialog({ open: InsuranceDialogState.InsuranceBasicDialog, insurance: undefined })
                  }
                >
                  {newInsuranceTxt}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <InsurancesTable
        insurancesData={insurancesData}
        promiseInProgress={promiseInProgress}
        setEditInsuranceDialog={setEditInsuranceDialog}
      />
      {insurancePage.canEditInsurances && (
        <InsuranceDialog
          open={editInsuranceDialog.open}
          insuranceDto={editInsuranceDialog.insurance}
          onClose={() => setEditInsuranceDialog({ open: InsuranceDialogState.None })}
          onSave={() => {
            setEditInsuranceDialog({ open: InsuranceDialogState.None });
            reload();
          }}
        />
      )}
    </React.Fragment>
  );
}
