import React, { useEffect, useMemo, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import red from '@mui/material/colors/red';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { KeycloakInitOptions } from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Dashboard } from './components/dashboard/Dashboard';
import keycloak from './common/keycloak';

// Log Token and Keycloak events for debugging
const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error);
};
const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens);
};

function App(): JSX.Element {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode);

  // Redirect to keycloak login page if not logged in
  const keycloakProviderInitConfig: KeycloakInitOptions = {
    onLoad: 'login-required',
    // Added recently
    // checkLoginIframe: false,
  };

  useEffect(() => {
    setIsDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkMode ? 'dark' : 'light',
          primary: {
            main: '#00477e',
          },
          secondary: {
            main: isDarkMode ? '#C79797' : '#b3b3b3',
          },
          error: {
            main: red.A400,
          },
          background: {
            default: isDarkMode ? '#141414' : '#fff',
          },
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: isDarkMode ? '#1C1C1C' : '#fff',
              },
            },
          },
        },
      }),
    [isDarkMode],
  );

  function themeToggle() {
    setIsDarkMode((prevMode) => !prevMode);
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={keycloakProviderInitConfig}
    >
      <React.StrictMode>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <Dashboard isDarkMode={isDarkMode} themeToggle={themeToggle} />
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
}

export default App;
