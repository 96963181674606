import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { NumberFormatEurText } from '../../../assets/numberformat/NumberFormatEur';
import { InsuranceInvoiceDto, ResponseError } from '../../../../generated';
import { InsuranceInvoicesTable } from './InsuranceInvoicesTable';
import useNotifications from '../../../assets/useNotifications';
import { getInsurancesApi } from '../../../../common/keycloak';
import InsuranceInvoiceDialog from './InsuranceInvoiceDialog';
import { useConfig } from '../../../../common/config';
import { useStyles } from '../../../Styles';

export function InsuranceInvoices(props: { invoiceExternalId: string }): JSX.Element {
  const classes = useStyles();
  const { insurancePage } = useConfig();

  // Trnaslations
  const { t } = useTranslation();
  const userUnauthorizedTxt = t('userUnauthorized');
  const errorFetchingDataTxt = t('errorFetchingData');
  const newInvoiceTxt = t('insuranceInvoices.newInvoice');
  const insuranceInvoicesTxt = t('insuranceInvoices.insuranceInvoices');
  const totalInvoiceAmountTxt = t('insuranceInvoices.totalInvoiceAmount');

  const { promiseInProgress } = usePromiseTracker({ area: 'insuranceInvoices', delay: 200 });

  const [reloadData, setReloadData] = useState(0);
  const reload = () => {
    setReloadData(reloadData + 1);
  };

  interface EditInsuranceInvoiceDialogData {
    open: boolean;
    insuranceInvoice?: InsuranceInvoiceDto;
  }

  const [editInsuranceInvoiceDialog, setEditInsuranceInvoiceDialog] = React.useState({
    open: false,
  } as EditInsuranceInvoiceDialogData);

  const { showError } = useNotifications();

  /** fetch data */
  const [insuranceInvoicesData, setInsuranceInvoicesData] = useState(new Array<InsuranceInvoiceDto>());
  const [totalInsuranceInvoiceAmount, setTotalInsuranceInvoiceAmount] = useState(0.0);
  useEffect(() => {
    const loadInsuranceInvoiceData = async () => {
      const api = await getInsurancesApi();
      try {
        const result = await api.getInsuranceInvoices(props.invoiceExternalId);
        setInsuranceInvoicesData(result);

        // Sum invoice total amount
        let totalAmount = 0.0;
        for (const invoice of result) totalAmount += invoice.invoiceDetails.invoiceAmount || 0.0;
        setTotalInsuranceInvoiceAmount(totalAmount);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError(userUnauthorizedTxt);
          } else {
            showError(error.message);
          }
        } else {
          showError(errorFetchingDataTxt);
        }
      }
    };
    trackPromise(loadInsuranceInvoiceData(), 'insuranceInvoices');
  }, [props.invoiceExternalId, showError, reloadData]);

  return (
    <>
      <Grid container sx={{ width: '100%' }}>
        <Grid size={{ xs: 12 }} style={{ flex: 1 }}>
          <Typography gutterBottom variant="h6">
            {insuranceInvoicesTxt}
          </Typography>
        </Grid>
        <Grid className={classes.buttonRight}>
          <Grid container spacing={2} justifyContent="flex-end" flexDirection="row">
            {insurancePage.canEditInsurances && (
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setEditInsuranceInvoiceDialog({ open: true, insuranceInvoice: undefined })}
                >
                  {newInvoiceTxt}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="body2">
        {totalInvoiceAmountTxt} <NumberFormatEurText value={totalInsuranceInvoiceAmount} />
      </Typography>

      <InsuranceInvoicesTable
        insurancesData={insuranceInvoicesData}
        promiseInProgress={promiseInProgress}
        setEditInsuranceInvoiceDialog={setEditInsuranceInvoiceDialog}
      />
      {insurancePage.canEditInsurances && (
        <InsuranceInvoiceDialog
          open={editInsuranceInvoiceDialog.open}
          insuranceInvoice={editInsuranceInvoiceDialog.insuranceInvoice}
          insuranceExternalId={props.invoiceExternalId}
          onClose={() => setEditInsuranceInvoiceDialog({ open: false })}
          onSave={() => {
            setEditInsuranceInvoiceDialog({ open: false });
            reload();
          }}
        />
      )}
    </>
  );
}
