import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  InputAdornment,
  TableSortLabel,
  Collapse,
  TablePagination,
  Box,
  Typography,
} from '@mui/material';
import {
  Edit,
  Search as SearchIcon,
  KeyboardArrowRight,
  KeyboardArrowDown,
  LastPage,
  KeyboardArrowLeft,
  FirstPage,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import DataGridSkeleton from '../DataGridSkeleton';
import { useStyles } from '../../Styles';
import { Title } from '../Title';
import {
  formatCordaX500Name,
  formatDateTimeToGermanStyle,
  formatNumberEur,
  formatNumberUnits,
} from '../../../common/format';
import { CordaX500NameDto } from '../../../generated';

type Order = 'asc' | 'desc';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

interface Column<T> {
  id: keyof T;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (value: any, row: T) => React.ReactNode;
}

interface CustomTableProps<T> {
  data: T[];
  columns: Column<T>[];
  title?: string;
  tableHeight?: string | number;
  promiseInProgress?: boolean;
  canEdit?: boolean;
  initialOrder?: Order;
  initialOrderBy?: keyof T | null;
  getRowId: (row: T) => string;
  onEdit?: (row: T) => void;
  onExpandRow?: (row: T) => React.ReactNode;
  reload?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomTable<T extends Record<string, any>>({
  data,
  columns,
  title,
  tableHeight,
  promiseInProgress,
  canEdit,
  initialOrder = 'asc',
  initialOrderBy,
  getRowId,
  onEdit,
  onExpandRow,
}: CustomTableProps<T>) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = useState<keyof T | null>(initialOrderBy ?? null);
  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [lastVisitedPage, setLastVisitedPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});
  const isDate = (value: unknown): value is Date => value instanceof Date;
  const CordaX500NameColArray = ['machineOwner', 'machineUser', 'paymentProvider', 'identity', 'counterparty'];
  const tableCell = {
    minWidth: 100,
    maxWidth: 300,
    whiteSpace: 'normal', // Allow text wrapping based on the content
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
  };

  // Trnaslations
  const { t } = useTranslation();
  const editTxt = t('edit');
  const searchTxt = t('search');
  const rowsPerPageTxt = t('tableToolbar.rowsPerPage');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  function handleSortRequest(property: keyof T) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      columns.some((col) => {
        const value = row[col.id];

        let formattedValue: string | undefined = undefined; // Ensure it's a string

        const columnId = String(col.id);

        // Handle formatCordaX500Name
        if (CordaX500NameColArray.includes(columnId) && value) {
          formattedValue = formatCordaX500Name(value);
        }

        // Handle price numbers
        if (['pricePerUnit', 'paymentThreshold', 'totalAmount', 'activityCost'].includes(columnId) && value) {
          formattedValue = formatNumberEur(value);
        }

        // Handle numbers
        if (['totalUnits', 'activityUnits'].includes(columnId) && value) {
          formattedValue = formatNumberUnits(value);
        }

        // Handle insurers array
        if (col.id === 'insurers') {
          formattedValue = value.map((insurer: CordaX500NameDto) => formatCordaX500Name(insurer)).join(' ');
        }

        // Handle dates
        formattedValue = isDate(value) ? formatDateTimeToGermanStyle(value) : formattedValue;

        // Handle rest of the columns
        if (
          [
            'machineName',
            'activityNote',
            'paymentStatus',
            'activityStatus',
            'insuranceStatus',
            'membershipStatus',
          ].includes(columnId) &&
          value
        ) {
          formattedValue = value;
        }

        return String(formattedValue ?? '')
          .toLowerCase()
          .includes(filterText.toLowerCase());
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, columns, filterText]);

  const sortedData = useMemo(() => {
    if (!orderBy) return filteredData;
    return [...filteredData].sort((a, b) => {
      const aValue = a[orderBy] ?? '';
      const bValue = b[orderBy] ?? '';

      // Sort dates
      if (typeof orderBy === 'string' && ['activityTimestamp', 'updated', 'created'].includes(orderBy)) {
        const aDate = aValue ? new Date(aValue).getTime() : 0;
        const bDate = bValue ? new Date(bValue).getTime() : 0;
        return order === 'asc' ? aDate - bDate : bDate - aDate;
      }

      // Sort numbers
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Sort CordaX500NameColArray
      if (typeof orderBy === 'string' && CordaX500NameColArray.includes(orderBy)) {
        const formattedAValue = aValue && typeof aValue === 'object' ? formatCordaX500Name(aValue) : '';
        const formattedBValue = bValue && typeof bValue === 'object' ? formatCordaX500Name(bValue) : '';

        return order === 'asc'
          ? formattedAValue.localeCompare(formattedBValue)
          : formattedBValue.localeCompare(formattedAValue);
      }

      // Sort strings
      return order === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, order, orderBy]);

  const paginatedData = useMemo(
    () => sortedData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage),
    [sortedData, currentPage, rowsPerPage],
  );

  function handleChangePage(_: unknown, newPage: number): void {
    setCurrentPage(newPage);
    setLastVisitedPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  function handleToggleRow(rowId: string) {
    setOpenRows((prev) => {
      return { ...prev, [rowId]: !prev[rowId] };
    });
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = currentPage > 0 ? Math.max(0, (1 + currentPage) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    if (filterText) {
      setCurrentPage(0);
    } else {
      setCurrentPage(lastVisitedPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  return (
    <Paper elevation={0} sx={{ width: '100%', p: 2 }}>
      {title && <Title>{title}</Title>}
      <TextField
        fullWidth
        placeholder={searchTxt}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        sx={{ mb: 2 }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
      {promiseInProgress ? (
        <DataGridSkeleton />
      ) : (
        <TableContainer
          className={classes.customScrollbarForMuiTable}
          component={Paper}
          sx={{
            mb: '1rem',
            overflowY: 'auto',
            height: tableHeight || 'auto',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {onExpandRow && <TableCell />}
                {columns.map((col) => (
                  <TableCell sx={tableCell} key={String(col.id)}>
                    <TableSortLabel
                      active={orderBy === col.id}
                      direction={orderBy === col.id ? order : 'asc'}
                      onClick={() => handleSortRequest(col.id)}
                    >
                      {col.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {canEdit && <TableCell align="center">{editTxt}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length + 2} align="center">
                    <Box
                      sx={{
                        height: '40vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography>{noRecordsToDisplayTxt}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedData.map((row) => (
                  <React.Fragment key={String(getRowId(row))}>
                    <TableRow>
                      {onExpandRow && (
                        <TableCell>
                          <IconButton size="small" onClick={() => handleToggleRow(String(getRowId(row)))}>
                            {openRows[getRowId(row)] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                          </IconButton>
                        </TableCell>
                      )}
                      {columns.map((col) => (
                        <TableCell sx={tableCell} key={String(col.id)}>
                          {col.render ? col.render(row[col.id], row) : row[col.id]}
                        </TableCell>
                      ))}
                      {canEdit && onEdit && (
                        <TableCell align="center">
                          <IconButton onClick={() => onEdit(row)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={columns.length + 2} sx={{ p: 0 }}>
                        <Collapse in={!!openRows[getRowId(row)]} timeout="auto" unmountOnExit>
                          {onExpandRow && <Box sx={{ p: 2 }}>{onExpandRow(row)}</Box>}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 70 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage={rowsPerPageTxt}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      />
    </Paper>
  );
}

// Function to render custom table pagination
function TablePaginationActions({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) {
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* First Page Button */}
      <IconButton onClick={(event) => onPageChange(event, 0)} disabled={page === 0}>
        <FirstPage />
      </IconButton>

      {/* Previous Page Button */}
      <IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>

      {/* Next Page Button */}
      <IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= lastPage}>
        <KeyboardArrowRight />
      </IconButton>

      {/* Last Page Button */}
      <IconButton onClick={(event) => onPageChange(event, lastPage)} disabled={page >= lastPage}>
        <LastPage />
      </IconButton>
    </Box>
  );
}
