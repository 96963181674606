import { useKeycloak } from '@react-keycloak/web';
import { Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useMediaQuery from '@mui/material/useMediaQuery';

import payperChainLogo from '../../images/PayperChain.svg';
import LanguageSelector from '../assets/LanguageSelector';
import PaymentDropDown from './PaymentDropDown';
import { ErrorPage } from '../pages/ErrorPage';
import { RoutingItems } from './RoutingItems';
import { useConfig } from '../../common/config';
import { Footer } from './Footer';
import UserMenu from './UserMenu';

interface DashboardProps {
  isDarkMode: boolean;
  themeToggle: () => void;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));

/**
 * This component is the base component which the user sees.
 */
export function Dashboard({ isDarkMode, themeToggle }: DashboardProps): JSX.Element {
  const theme = useTheme();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const [paymentDropDownAnchorEl, setPaymentDropDownAnchorEl] = React.useState<HTMLElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(!isMobile);
  const { dashboard, homeRoute, routes } = useConfig();

  // Update `open` when screen size changes
  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  // Custom styles
  const payperChainLogoStyles = {
    '& > img': {
      height: 50,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: '#b3b3b3',
      borderRadius: '20%',
      marginRight: 2,
      backgroundColor: 'white',
    },
  };

  const content = {
    flexGrow: 1,
    p: { xs: 0, sm: 0, md: 3 },
    height: '100vh',
    overflow: 'auto',
  };

  const container = {
    padding: { xs: '8px 0', sm: '8px 0', md: '32px 16px' },
  };

  const paper = {
    padding: { xs: '8px', sm: '8px', md: '16px' },
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  };

  // Open and close functions
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Wait for auth check
  const k = useKeycloak();
  if (!k.initialized) {
    return <div>Loading...</div>;
  }

  // Draw Dashboard
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ paddingRight: 24 }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open main drawer"
            onClick={handleDrawerOpen}
            sx={[{ marginRight: 36, margin: '0px 70px 0px -12px' }, open && { display: 'none' }]}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={payperChainLogoStyles}>
            <img src={payperChainLogo} alt="PayperChain Logo" />
          </Box>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
            }}
          >
            {dashboard.title + ' – Dashboard'}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={themeToggle}
            sx={{
              marginRight: '16px',
            }}
          >
            {isDarkMode ? <Brightness2Icon /> : <WbSunnyIcon />}
          </IconButton>
          <LanguageSelector />

          {dashboard.allowManagePaymentInformation && (
            <IconButton
              color="inherit"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setPaymentDropDownAnchorEl(event.currentTarget)}
            >
              <CreditCardIcon />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setUserMenuAnchorEl(event.currentTarget)}
          >
            <AccountCircleIcon />
          </IconButton>
          <UserMenu anchorEl={userMenuAnchorEl} onClose={() => setUserMenuAnchorEl(null)} />
          <PaymentDropDown anchorEl={paymentDropDownAnchorEl} onClose={() => setPaymentDropDownAnchorEl(null)} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <RoutingItems />
        </List>
      </Drawer>
      <Box component="main" sx={content}>
        <DrawerHeader />
        <Container maxWidth={false} sx={container}>
          <Grid container spacing={3}>
            {/* Table */}
            <Grid size={12}>
              <Paper sx={paper}>
                <Routes>
                  {homeRoute && <Route path="/" element={<homeRoute.component />} />}
                  {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={<route.component />} />
                  ))}
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
